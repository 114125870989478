<template>
  <div>
    <div class="nosology" v-if="Nosologys && Nosologys.length">
      <div class="content">
        <div class="nosology__title">Терапевтические области</div>
      </div>
      <Breadcrumbs :pages="[{ name: 'Терапевтические области' }]" />
      <div class="content">
        <div class="nosology__list">
          <NosologyItem
            v-for="(item, ind) in Nosologys"
            :key="ind"
            :item="formattedImageList(item, 'list')"
          />
        </div>
      </div>
    </div>
    <div v-else class="preloader detail-page__preloader">
      <Preloader class="" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumbs from "../components/pageComponents/Breadcrumbs.vue";
import NosologyItem from "../components/pageComponents/NosologyItem.vue";
import Preloader from "@/components/Preloader.vue";
export default {
  metaInfo: {
    title: "Терапевтические области",
  },
  name: "Nosology",
  components: { Breadcrumbs, NosologyItem, Preloader },
  data: () => ({}),
  computed: {
    ...mapGetters(["Nosologys"]),
  },
  methods: {
    formattedImageList(item, tag) {
      if(item.images && item.images.length) {
        item.image =
        this.$root.host + item.images?.find((el) => el.tag === tag)?.image;
      }   
      return item
    },
  },
};
</script>

<style lang="scss" scoped>
.nosology {
  padding-top: 64px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 32px;
  }
  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }

  &__list {
    margin-top: 64px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 32px;
    row-gap: 32px;

    @media screen and (max-width: 1220px) {
      grid-template-columns: 1fr;
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }
}
</style>
